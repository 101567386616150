.social {
    position: fixed;
    bottom: 3vh;
    z-index: 500;
    /* width: 100vw; */
  
  
    /* display: flex; */
    /* position: absolute; */
    /* justify-self: center; */
    /* width: 100%; */
    /* bottom: 60px; */
    
  }
  
  .social-container {
    width: 100vw;
    justify-content: center;
    gap: 25px;
  
    /* width: 300px; */
    border: 0px;
    /* border-radius: 10px; */
    background-color: rgba(0, 0, 0, 0.12);
    padding: 8px;
    display: flex;
    flex-direction: wrap;
    /* justify-content: space-around; */
    margin-top: 2em;
    /* margin-bottom: 3vh; */
    backdrop-filter: blur( 5.0px );
    -webkit-backdrop-filter: blur( 5.0px );
  }
  
  .social-link {
    display: block;
    justify-content: center;
    padding: 9px;
    /* align-content: center; */
    cursor: pointer;
    height: 58px;
    width: 58px;
    background-color: white;
    border-radius: 15px;
  }
  
  .social-link:hover {
    -moz-box-shadow: 0 0 10px #000000;
    /* -webkit-box-shadow: inset 0 0 10px #000000; */
    box-shadow:  0 0 10px #000000;
  }
  
  .social-link img {
    height: 40px;
  }

@media (max-width: 768px) {

    .social {
        bottom: 0.5vh;
    }
  
    .social-container {
      gap: 25px;
      padding: 8px;
      margin-bottom: 4vh;
    }
  
    .social-link {
      padding: 9px;
      height: 58px; 
      width: 58px;
      border-radius: 15px;
    }
    
    .social-link img {
      height: 40px;
    }
  }
