.about-container {
    padding: 20px 15vw;
    width: 93vw;
    /* margin-top: 80px; */
    /* margin: 100px auto 120px auto; */
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 120px;
    /* scroll-margin: 0 0 120px 0; */
    max-width: 890px;
    border: 1px solid rgba(0,0,0,.1);
    padding: 2rem;
    font-family: "Montserrat", sans-serif;
    /* font-weight: 200; */
    font-size: 0.85rem;
    /* background-color: #ffffff; */
    opacity: 0.75;
    /* margin-bottom: 10px; */
    background: rgba( 229, 129, 9, 0.30 );
    background-color: rgba(0, 0, 0, 0.12);
    backdrop-filter: blur( 5.0px );
    -webkit-backdrop-filter: blur( 5.0px );
    border-radius: 10px;
    padding-bottom: 120px;
}

.about-images-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    margin-top: 5vh;
}

.about-images-container img {
    width: 15vw;
    background-color: white;
    border-radius: 10px;
    max-width: 140px;
}

@media (max-width: 768px) {

    .about-container {
        padding: 15px 1vw;
    }

    .about-container img {
        width: 20vw;

    }

    .about-images-container {
        justify-content: space-between;
        width: 17vw;
        gap: 10px;
    }

    .about-images-container img {
        border-radius: 5px;
    }
    

}