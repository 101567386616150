.contact-container {
  padding-top: 20px 15vw;
  /* height: 75vh; */
  width: 100vw;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-self: center;
  /* margin-top: 80px; */
  /* padding-bottom: 100px; */
}
  
.contact-container h1 {
  margin-bottom: 20px;
  color: #000000;
}
  
.contact-container .container-fluid {
  /* box-shadow: 5px 10px 10px #888888; */
  border-radius: 10px;
  padding: 0;
  max-width: 890px;
}
  
.contact-container form {
  border: 1px solid rgba(0,0,0,.1);
  padding: 2rem;
  font-family: "Montserrat", sans-serif;
  /* font-weight: 200; */
  font-size: 0.85rem;
  /* background-color: #ffffff; */
  opacity: 0.75;
  
  background: rgba( 229, 129, 9, 0.30 );
  background-color: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur( 5.0px );
  -webkit-backdrop-filter: blur( 5.0px );
  border-radius: 10px;
}

form .form-col {
  height: auto;
}
  
form .form-btn-row {
  display: flex;
  justify-content: flex-end;
}
  
form .form-btn-row button {
  margin: 10px 20px;
}

@media (max-width: 768px) {
  .contact-container {
    padding: 15px 1vw;
    /* height: 140vh; */
    width: 100vw;
    margin-top: 80px;
  }

  .contact-container .container-fluid {
    box-shadow: 0px 0px 0px ;
  }

  .contact-container form {
    padding: 1rem;
  }

  form .form-btn-row {
    display: flex;
    justify-content: center;
  }
}