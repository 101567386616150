* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

hr {
  margin: 5px;
}

.btn {
  padding: 10px 20px;
  margin: 20px auto;
  font-family: "Montserrat", sans-serif;
  /* font-weight: 200; */
  font-size: 1rem;
  /* background-color: Black; */
  /* border-color: Black; */
}

/* .btn:hover {
  background-color: White;
  border-color: Black;
  color: Black;
} */

.App {
  /* position: absolute; */
  /* overflow: auto; */
  font-family: 'Source Sans Pro', sans-serif;
  
  /* flex-direction: column; */
  /* text-align: center; */
}

/* Bottom left corner */
.copyright-text {
  z-index: 500;
  position: fixed;
  bottom: -14px;
  left: 20px;
  font-size: 14px;
  color: #606060;
}

/* Start of link behaivour */

a:hover, a:active {
  color: #ffa500;
  /* filter: drop-shadow(1px 1px 1px #555555); */
  /* border-bottom: #ffa500 solid 3px; */
}

/* a:active {
  border-bottom: #ffa500 solid 3px;
} */

.nav-button {
  color: #555555;
  cursor: pointer;
  font-weight: 800;
  font-size: 20px;
  
  
  /* margin-bottom: 10px; */
}

.nav-button:hover {
  color: #ffa500;
  text-decoration: none;
}

/* End of link behaivour */

/* Start of Header */

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  height: 100px;
  background-color: #fcfcfc;
}

.header .logo {
  display: flex;
  flex-direction: row;
}

.logo img {
  height: 65px;
  width: 65px;
  position: absolute;
  top: 3px;
  /* margin-top: 2px; */
}

/* End of Header */

.logo-text {
  /* font-style: italic; */
  font-weight: 700;
  font-size: 1.5em;
}

.main-page {
  /* position: relative; */
  z-index: 1;
  /* top: 100px; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: stretch; */
  /* margin-right: 100px; */
  /* padding-right: 100px; */
  /* padding-top: 75px; */
  width: 100vw;
  /* overflow-y: auto; */
  /* align-items: center; */
  /* justify-items: center; */
  /* align-content: center; */
  scroll-margin: 0 0 120px 0;
}

/* Start of Intro Page */

.particles {
  z-index: -1;
  height: 100vh;
  width: 100vw;
  position: fixed;
}

.intro-page-content {
  display: flex;
  position: absolute;
  flex-direction: column;
  padding-top: 25vh;
  /* justify-content: center; */
  height: 100%;
  text-align: center;
  /* align-items: stretch; */
  /* align-content: center; */
  width: 100vw;
  /* position: relative; */
  /* background-color: transparent; */
}

.intro-logo img {
  /* width: fit-content; */
  height: 25vh;
}

.intro-logo-text {
  /* font-style: italic; */
  font-weight: 700;
  font-size: 2.5em;
}

.strapline {
  font-style: italic;
  font-weight: bold;
  font-size: 1.5em;
}

.nav-link {
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 1.6em;
  font-weight: 600;
  color: #555555;
}

.nav-link:hover {
  color: #ffa500;
  text-decoration: none;
}

/* end of Intro Page */

.switched-page {
  width: 100vw;
  height: 100%;
  cursor: pointer;
}

@media (max-width: 768px) {

}

@media (max-width: 400px) {
  .copyright-text {
    left: 5px;
    font-size: 12px;
  }
}