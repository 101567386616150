@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Raleway:wght@300;400;500;600;700&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

/* html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
} */
/* HTML5 display-role reset for older browsers */
/* article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
} */

/* ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
} */

/* body {
  height: 100%;
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

html {
	font-family: 100%/1.5 "Montserrat", sans-serif;
  }
  
  body {
	text-rendering: optimizeLegibility;
  }
  
  body,
  input,
  button {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  
  body h1,
  body h2 {
	font-family: "Raleway", sans-serif;
	font-size: 1.5rem;
  }
  
  body h2 {
	font-weight: 500;
  }
  
  body h1 {
	font-weight: 400;
  }
  
  body span {
	font-weight: 200;
	font-size: 1rem;
  }
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

hr {
  margin: 5px;
}

.btn {
  padding: 10px 20px;
  margin: 20px auto;
  font-family: "Montserrat", sans-serif;
  /* font-weight: 200; */
  font-size: 1rem;
  /* background-color: Black; */
  /* border-color: Black; */
}

/* .btn:hover {
  background-color: White;
  border-color: Black;
  color: Black;
} */

.App {
  /* position: absolute; */
  /* overflow: auto; */
  font-family: 'Source Sans Pro', sans-serif;
  
  /* flex-direction: column; */
  /* text-align: center; */
}

/* Bottom left corner */
.copyright-text {
  z-index: 500;
  position: fixed;
  bottom: -14px;
  left: 20px;
  font-size: 14px;
  color: #606060;
}

/* Start of link behaivour */

a:hover, a:active {
  color: #ffa500;
  /* filter: drop-shadow(1px 1px 1px #555555); */
  /* border-bottom: #ffa500 solid 3px; */
}

/* a:active {
  border-bottom: #ffa500 solid 3px;
} */

.nav-button {
  color: #555555;
  cursor: pointer;
  font-weight: 800;
  font-size: 20px;
  
  
  /* margin-bottom: 10px; */
}

.nav-button:hover {
  color: #ffa500;
  text-decoration: none;
}

/* End of link behaivour */

/* Start of Header */

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  height: 100px;
  background-color: #fcfcfc;
}

.header .logo {
  display: flex;
  flex-direction: row;
}

.logo img {
  height: 65px;
  width: 65px;
  position: absolute;
  top: 3px;
  /* margin-top: 2px; */
}

/* End of Header */

.logo-text {
  /* font-style: italic; */
  font-weight: 700;
  font-size: 1.5em;
}

.main-page {
  /* position: relative; */
  z-index: 1;
  /* top: 100px; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: stretch; */
  /* margin-right: 100px; */
  /* padding-right: 100px; */
  /* padding-top: 75px; */
  width: 100vw;
  /* overflow-y: auto; */
  /* align-items: center; */
  /* justify-items: center; */
  /* align-content: center; */
  scroll-margin: 0 0 120px 0;
}

/* Start of Intro Page */

.particles {
  z-index: -1;
  height: 100vh;
  width: 100vw;
  position: fixed;
}

.intro-page-content {
  display: flex;
  position: absolute;
  flex-direction: column;
  padding-top: 25vh;
  /* justify-content: center; */
  height: 100%;
  text-align: center;
  /* align-items: stretch; */
  /* align-content: center; */
  width: 100vw;
  /* position: relative; */
  /* background-color: transparent; */
}

.intro-logo img {
  /* width: fit-content; */
  height: 25vh;
}

.intro-logo-text {
  /* font-style: italic; */
  font-weight: 700;
  font-size: 2.5em;
}

.strapline {
  font-style: italic;
  font-weight: bold;
  font-size: 1.5em;
}

.nav-link {
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 1.6em;
  font-weight: 600;
  color: #555555;
}

.nav-link:hover {
  color: #ffa500;
  text-decoration: none;
}

/* end of Intro Page */

.switched-page {
  width: 100vw;
  height: 100%;
  cursor: pointer;
}

@media (max-width: 768px) {

}

@media (max-width: 400px) {
  .copyright-text {
    left: 5px;
    font-size: 12px;
  }
}
.about-container {
    padding: 20px 15vw;
    width: 93vw;
    /* margin-top: 80px; */
    /* margin: 100px auto 120px auto; */
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 120px;
    /* scroll-margin: 0 0 120px 0; */
    max-width: 890px;
    border: 1px solid rgba(0,0,0,.1);
    padding: 2rem;
    font-family: "Montserrat", sans-serif;
    /* font-weight: 200; */
    font-size: 0.85rem;
    /* background-color: #ffffff; */
    opacity: 0.75;
    /* margin-bottom: 10px; */
    background: rgba( 229, 129, 9, 0.30 );
    background-color: rgba(0, 0, 0, 0.12);
    backdrop-filter: blur( 5.0px );
    -webkit-backdrop-filter: blur( 5.0px );
    border-radius: 10px;
    padding-bottom: 120px;
}

.about-images-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    margin-top: 5vh;
}

.about-images-container img {
    width: 15vw;
    background-color: white;
    border-radius: 10px;
    max-width: 140px;
}

@media (max-width: 768px) {

    .about-container {
        padding: 15px 1vw;
    }

    .about-container img {
        width: 20vw;

    }

    .about-images-container {
        justify-content: space-between;
        width: 17vw;
        grid-gap: 10px;
        gap: 10px;
    }

    .about-images-container img {
        border-radius: 5px;
    }
    

}
.contact-container {
  padding-top: 20px 15vw;
  /* height: 75vh; */
  width: 100vw;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-self: center;
  /* margin-top: 80px; */
  /* padding-bottom: 100px; */
}
  
.contact-container h1 {
  margin-bottom: 20px;
  color: #000000;
}
  
.contact-container .container-fluid {
  /* box-shadow: 5px 10px 10px #888888; */
  border-radius: 10px;
  padding: 0;
  max-width: 890px;
}
  
.contact-container form {
  border: 1px solid rgba(0,0,0,.1);
  padding: 2rem;
  font-family: "Montserrat", sans-serif;
  /* font-weight: 200; */
  font-size: 0.85rem;
  /* background-color: #ffffff; */
  opacity: 0.75;
  
  background: rgba( 229, 129, 9, 0.30 );
  background-color: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur( 5.0px );
  -webkit-backdrop-filter: blur( 5.0px );
  border-radius: 10px;
}

form .form-col {
  height: auto;
}
  
form .form-btn-row {
  display: flex;
  justify-content: flex-end;
}
  
form .form-btn-row button {
  margin: 10px 20px;
}

@media (max-width: 768px) {
  .contact-container {
    padding: 15px 1vw;
    /* height: 140vh; */
    width: 100vw;
    margin-top: 80px;
  }

  .contact-container .container-fluid {
    box-shadow: 0px 0px 0px ;
  }

  .contact-container form {
    padding: 1rem;
  }

  form .form-btn-row {
    display: flex;
    justify-content: center;
  }
}
.social {
    position: fixed;
    bottom: 3vh;
    z-index: 500;
    /* width: 100vw; */
  
  
    /* display: flex; */
    /* position: absolute; */
    /* justify-self: center; */
    /* width: 100%; */
    /* bottom: 60px; */
    
  }
  
  .social-container {
    width: 100vw;
    justify-content: center;
    grid-gap: 25px;
    gap: 25px;
  
    /* width: 300px; */
    border: 0px;
    /* border-radius: 10px; */
    background-color: rgba(0, 0, 0, 0.12);
    padding: 8px;
    display: flex;
    flex-direction: wrap;
    /* justify-content: space-around; */
    margin-top: 2em;
    /* margin-bottom: 3vh; */
    backdrop-filter: blur( 5.0px );
    -webkit-backdrop-filter: blur( 5.0px );
  }
  
  .social-link {
    display: block;
    justify-content: center;
    padding: 9px;
    /* align-content: center; */
    cursor: pointer;
    height: 58px;
    width: 58px;
    background-color: white;
    border-radius: 15px;
  }
  
  .social-link:hover {
    /* -webkit-box-shadow: inset 0 0 10px #000000; */
    box-shadow:  0 0 10px #000000;
  }
  
  .social-link img {
    height: 40px;
  }

@media (max-width: 768px) {

    .social {
        bottom: 0.5vh;
    }
  
    .social-container {
      grid-gap: 25px;
      gap: 25px;
      padding: 8px;
      margin-bottom: 4vh;
    }
  
    .social-link {
      padding: 9px;
      height: 58px; 
      width: 58px;
      border-radius: 15px;
    }
    
    .social-link img {
      height: 40px;
    }
  }

